<template>
  <div>
    <v-list-item @click="openDetail">
      <v-list-item-avatar>
        <v-avatar :color="color">
          {{ avatar }}
        </v-avatar>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-text="name"></v-list-item-title>
        <v-list-item-subtitle v-text="source.username"></v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <edit-profile v-model="source" :isUpdate="true" />
      </v-list-item-action>
    </v-list-item>
    <v-divider inset />
  </div>
</template>

<script>
export default {
  props: ["source"],
  components: {
    'edit-profile': () => import("./EditProfile.vue")
  },
  computed: {
    color() {
      return this.source.role === "admin" ? "warning" : "success";
    },
    name() {
      const array = [];
      if (this.source.firstName) {
        array.push(this.source.firstName);
      }
      if (this.source.lastName) {
        array.push(this.source.lastName);
      }
      return (
        array.join(" ").toUpperCase().trim() ||
        this.source.username.toUpperCase()
      );
    },
    avatar() {
      const array = [];
      if (this.source.firstName) {
        array.push(this.source.firstName[0]);
      }
      if (this.source.lastName) {
        array.push(this.source.lastName[0]);
      }
      return (
        array.join("").toUpperCase().trim() ||
        this.source.username[0].toUpperCase()
      );
    }
  },
  methods: {
    openDetail() {}
  }
};
</script>
